import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar'
import Footer from '../SharedComponents/Footer';
import axios from 'axios';
import Datepicker from "react-tailwindcss-datepicker";

import { BaseUrlConfig } from '../BaseUrlConfig';

class Data extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            value: { 'startDate': (new Date()).toISOString().split('T')[0], 'endDate': (new Date()).toISOString().split('T')[0] },
            value_2: { 'startDate': (new Date()).toISOString().split('T')[0], 'endDate': (new Date()).toISOString().split('T')[0] },
            current_station_name_display: "",
            selected_station: "",
            station_list: [],
            error: false,
            error_message: "",
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        this.fetchDataActual()
        if (localStorage.getItem("serial_number") != null) {
            this.setState({
                selected_station: localStorage.getItem("serial_number")
            }, () => {
                localStorage.removeItem("serial_number")
                this.submitHandler()
            })
        }
        else {
            this.fetchDefaultTableData()
        }
        // this.fetchData()
    }

    fetchData = () => {
        var url = "https://jps-hydstra-app.vercel.app/api/testdata"
        try {
            axios.get(url)
                .then(response => {
                    this.setState({
                        data: response.data,
                        loading: false
                    })
                })
        }
        catch (error) {
            console.log(error)
        }
    }

    fetchDataActual = () => {
        axios.post(BaseUrlConfig() + '/specconnect/get', {
            "user_tag": localStorage.getItem("user_tag")
        })
            .then(response => {
                this.setState({
                    station_list: response.data
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchDefaultTableData = () => {
        axios.post(BaseUrlConfig() + '/specconnect/get/default', {
            "user_tag": localStorage.getItem("user_tag")
        })
            .then(response => {
                this.setState({
                    current_station_name_display: response.data[0],
                    data: response.data[1],
                    loading: false
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getDataCell = () => {
        if (this.state.data.length === 0) {
            return (
                <tr classname="hover">
                    <th></th>
                    <td colSpan={16} style={{ textAlign: "center" }}>Tiada Data.</td>
                </tr>
            )
        }
        else {
            return this.state.data.map((row) => {
                return (
                    // Test Data
                    // <tr className="hover">
                    //     <th></th>
                    //     <td>{row.col1}</td>
                    //     <td>{row.col2}</td>
                    //     <td>{row.col3}</td>
                    //     <td>{row.col4}</td>
                    //     <td>{row.col5}</td>
                    //     <td>{row.col6}</td>
                    //     <td>{row.col7}</td>
                    //     <td>{row.col8}</td>
                    //     <td>{row.col9}</td>
                    //     <td>{row.col10}</td>
                    //     <td>{row.col11}</td>
                    //     <td>{row.col12}</td>
                    //     <td>{row.col13}</td>
                    //     <td>{row.col14}</td>
                    //     <td>{row.col15}</td>
                    //     <td>{row.col16}</td>
                    // </tr>

                    // Mock data from Specconnect
                    <tr className="hover" key={row.col1}>
                        <th></th>
                        <td style={{ textAlign: "center" }}>{row.col1}</td>
                        <td style={{ textAlign: "center" }}>{row.col2}</td>
                        <td style={{ textAlign: "center" }}>{row.col3}</td>
                        <td style={{ textAlign: "center" }}>{row.col4}</td>
                        <td style={{ textAlign: "center" }}>{row.col5}</td>
                    </tr>
                )
            })
        }

    }

    handleValueChange = (value) => {
        this.setState({
            value: value
        })
    }

    handleValueChange_2 = (value) => {
        this.setState({
            value_2: value
        })
    }

    handleStationChanged = (event) => {
        this.setState({
            selected_station: event.target.value
        })
    }

    submitHandler = () => {
        if (this.state.value === null || this.state.value_2 === null) {
            console.log("Cannot be null")
        }
        else if (this.state.selected_station === "") {
            console.log("Select a station")
        }
        else {
            this.setState({
                loading: true
            })
            axios.post(BaseUrlConfig() + '/specconnect/getRangeData',
                {
                    "serial_number": this.state.selected_station,
                    "start_date": this.state.value["startDate"],
                    "end_date": this.state.value_2["startDate"]
                })
                .then(response => {
                    console.log(response)
                    this.setState({
                        loading: false,
                        current_station_name_display: response.data[0],
                        data: response.data[1]
                    }, () => {
                        console.log(this.state.data)
                    })
                })
        }
    }

    render() {
        const loadingContent = (
            <React.Fragment>
                <div className="flex justify-center pt-10">
                    <span className="loading loading-ring loading-lg text-success w-2/5"></span>

                </div>
                <br />

                <div className="flex justify-center">
                    <a href="#!" className="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                            animate-pulse">
                            Sila tunggu, data sedang dimuatkan...</span>
                    </a>
                </div>


            </React.Fragment>
        )

        const mainContent = (
            <React.Fragment>
                {/* <div>
                    <h1>**Data here is only for testing purposes**</h1>
                </div> */}
                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-3xl">Data Hujan</h1>
                </div>

                {localStorage.getItem("user_tag") === "jmg_n9" ?
                    <div className="flex justify-center pt-2">
                        <h3 className="text-sm italic">Data dari JMG Negeri Sembilan/Melaka</h3>
                    </div>
                    :
                    null
                }

                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-xl">{this.state.current_station_name_display}</h1>
                </div>

                <div className="flex justify-center">
                    <h1 className="font-bold text-xl">Data Stesen</h1>
                </div>

                <br />

                <div className="flex justify-center">
                    <div className="collapse collapse-arrow bg-green-100 w-[calc(100vw-3rem)] lg:w-[calc(100vw-30rem)]">
                        <input type="checkbox" className="peer" />
                        <div className="collapse-title bg-green-100">
                            Klik di sini untuk melihat pilihan penapis data
                        </div>
                        <div className="collapse-content bg-green-100 peer-checked:bg-green-200">
                            <div>
                                <div className="text-left italic p-4">
                                    Notis Penting: Harap dimaklumkan bahawa data lengkap hanya boleh dipaparkan jika bilangan hari untuk tarikh mula dan tarikh tamat adalah 7 hari atau ke bawah.
                                </div>
                            </div>
                            <div className="font-bold pt-6">
                                <div className="text-left text-2xl underline underline-offset-8 p-4">
                                    Penapis data
                                </div>

                                <br />
                                <br />

                                <div className="flex justify-center">
                                    <select className="select select-bordered select-md w-full max-w-xs"
                                        onChange={this.handleStationChanged}>
                                        <option disabled selected>Sila pilih stesen...</option>
                                        {this.state.station_list.map((data) => (
                                            <option key={data.serial_number} value={data.serial_number}>{data.station_name}</option>
                                        ))}
                                    </select>
                                </div>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <div className="flex -mb-16 px-6 gap-6">
                                    <span className="w-2/4">Tarikh Mula</span>

                                    <span className="w-2/4">Tarikh Tamat</span>
                                </div>

                                <div className="flex items-center justify-center h-48 px-6 gap-6">
                                    <Datepicker
                                        useRange={false}
                                        asSingle={true}
                                        value={this.state.value}
                                        onChange={this.handleValueChange}
                                        popoverDirection="up"
                                    />

                                    <Datepicker
                                        useRange={false}
                                        asSingle={true}
                                        value={this.state.value_2}
                                        onChange={this.handleValueChange_2}
                                        popoverDirection="up"
                                    />
                                </div>

                                <div className="flex items-center justify-center h-16 gap-8">
                                    <button className="btn btn-info w-32"
                                        onClick={this.submitHandler}>Cari</button>
                                    <button className="btn btn-active btn-secondary w-32">Semula</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="overflow-x-auto px-6 h-[calc(100vh-10rem)] w-screen lg:w-[calc(100vw-21rem)]">
                    <table className="table table-xs table-pin-rows table-pin-cols border-l border-r border-gray-300">
                        <thead className="text-lg">
                            <tr>
                                {/* Test Data */}
                                {/* <th></th>
                                <td>Date/Time</td>
                                <td>TMPA</td>
                                <td>VWCB</td>
                                <td>WET</td>
                                <td>PAR</td>
                                <td>SRD</td>
                                <td>HMD</td>
                                <td>TMP</td>
                                <td>RNF</td>
                                <td>WND</td>
                                <td>WNG</td>
                                <td>WNS</td>
                                <td>BTL</td>
                                <td>DEW</td>
                                <td>ECBC</td>
                                <td>VWCD</td> */}

                                {/* Mock data from Specconnect */}
                                <th></th>
                                <td style={{ width: "20%", textAlign: "center" }}>Tarikh/Masa</td>
                                <td style={{ width: "20%", textAlign: "center" }}>Hujan (mm)</td>
                                <td style={{ width: "20%", textAlign: "center" }}>Hujan Terkumpul (mm)</td>
                                <td style={{ width: "20%", textAlign: "center" }}>Suhu (°C)</td>
                                <td style={{ width: "20%", textAlign: "center" }}>Kelembapan Relatif (%RH)</td>

                            </tr>
                        </thead>
                        <tbody>
                            {this.getDataCell()}
                        </tbody>
                    </table>
                </div>

                <br />
                <br />


            </React.Fragment>
        )
        return (
            <React.Fragment>
                {this.state.loading ?
                    <NavSideBar pageContent={loadingContent} />
                    :
                    <NavSideBar pageContent={mainContent} />
                }

                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(Data);