import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar'
import Footer from '../SharedComponents/Footer';
import axios from 'axios';

import { BaseUrlConfig } from '../BaseUrlConfig';

class StationImageData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            current_station_name_display: "",
            selected_station: "",
            serial_number: "",
            station_list: [],
            error: false,
            error_message: "",
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        this.fetchDataActual()
        if (localStorage.getItem("serial_number") != null) {
            this.setState({
                selected_station: localStorage.getItem("serial_number")
            }, () => {
                localStorage.removeItem("serial_number")
                this.submitHandler()
            })
        }
        else {
            this.fetchDefaultTableData()
        }
        // this.fetchData()
    }

    fetchDataActual = () => {
        axios.post(BaseUrlConfig() + '/specconnect/get', {
            "user_tag": localStorage.getItem("user_tag")
        })
            .then(response => {
                this.setState({
                    station_list: response.data
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchDefaultTableData = () => {
        axios.get(BaseUrlConfig() + '/image/get/default')
            .then(response => {
                this.setState({
                    current_station_name_display: response.data[0],
                    serial_number: response.data[1],
                    data: response.data[2],
                    loading: false
                })
            })
            .catch(error => {
                console.log(error)
            })
        this.setState({
            loading: false
        })
    }

    getDataCell = () => {
        if (this.state.data.length === 0) {
            return (
                <tr classname="hover">
                    <th></th>
                    <td colSpan={16} style={{ textAlign: "center" }}>Tiada Data.</td>
                </tr>
            )
        }
        else {
            return this.state.data.map((row) => {
                return (
                    <tr className="hover" key={row.idx}>
                        <th></th>
                        <td style={{ textAlign: "center" }}>{row.created_at}</td>
                        <td style={{ textAlign: "center" }}>
                            <button className="btn btn-primary w-48" onClick={() => this.openImage(row["filename"])}>Gambar</button>
                        </td>
                    </tr>
                )
            })
        }

    }

    handleStationChanged = (event) => {
        this.setState({
            selected_station: event.target.value
        })
    }

    submitHandler = () => {
        if (this.state.selected_station === "") {
            console.log("Select a station")
        }
        else {
            this.setState({
                loading: true
            })
            axios.post(BaseUrlConfig() + '/image/get',
                {
                    "station_name": this.state.selected_station,
                })
                .then(response => {
                    console.log(response)
                    this.setState({
                        loading: false,
                        current_station_name_display: response.data[0],
                        serial_number: response.data[1],
                        data: response.data[2]
                    }, () => {
                        console.log(this.state.data)
                    })
                })
        }
    }

    openImage = (filename) => {
        axios.post(BaseUrlConfig() + '/image/get/image',
            {
                "serial_number": this.state.serial_number,
                "image_filename": filename
            })
            .then(response => {
                console.log(response)
                window.open(BaseUrlConfig() + response.data)
            })
    }

    render() {
        const loadingContent = (
            <React.Fragment>
                <div className="flex justify-center pt-10">
                    <span className="loading loading-ring loading-lg text-success w-2/5"></span>

                </div>
                <br />

                <div className="flex justify-center">
                    <a href="#!" className="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                            animate-pulse">
                            Sila tunggu, data sedang dimuatkan...</span>
                    </a>
                </div>


            </React.Fragment>
        )

        const mainContent = (
            <React.Fragment>
                {/* <div>
                    <h1>**Data here is only for testing purposes**</h1>
                </div> */}
                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-3xl">Senarai Gambar Stesen</h1>
                </div>

                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-xl">{this.state.current_station_name_display}</h1>
                </div>

                <div className="flex justify-center">
                    <div className="collapse collapse-arrow bg-green-100 w-[calc(100vw-3rem)] lg:w-[calc(100vw-30rem)]">
                        <input type="checkbox" className="peer" />
                        <div className="collapse-title bg-green-100">
                            Klik di sini untuk melihat pilihan penapis data
                        </div>
                        <div className="collapse-content bg-green-100 peer-checked:bg-green-200">
                            <div className="font-bold pt-6">
                                <div className="text-left text-2xl underline underline-offset-8 p-4">
                                    Penapis data
                                </div>

                                <div className="flex justify-center">
                                    <select className="select select-bordered select-md w-full max-w-xs"
                                        onChange={this.handleStationChanged}>
                                        <option disabled selected>Sila pilih stesen...</option>
                                        {this.state.station_list.map((data) => (
                                            <option key={data.serial_number} value={data.serial_number}>{data.station_name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="flex items-center justify-center h-16 gap-8">
                                    <button className="btn btn-info w-32"
                                        onClick={this.submitHandler}>Cari</button>
                                    <button className="btn btn-active btn-secondary w-32">Semula</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="overflow-x-auto px-6 h-[calc(100vh-10rem)] w-screen lg:w-[calc(100vw-21rem)]">
                    <table className="table table-xs table-pin-rows table-pin-cols border-l border-r border-gray-300">
                        <thead className="text-lg">
                            <tr>
                                <th></th>
                                <td style={{ width: "80%", textAlign: "center" }}>Tarikh/Masa</td>
                                <td style={{ width: "20%", textAlign: "center" }}>Action</td>

                            </tr>
                        </thead>
                        <tbody>
                            {this.getDataCell()}
                        </tbody>
                    </table>
                </div>

                <br />
                <br />


            </React.Fragment>
        )
        return (
            <React.Fragment>
                {this.state.loading ?
                    <NavSideBar pageContent={loadingContent} />
                    :
                    <NavSideBar pageContent={mainContent} />
                }

                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(StationImageData);
