import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { withNavigate } from '../Utilities/Navigation'
import NavSideBar from '../SharedComponents/NavSideBar'
import Footer from '../SharedComponents/Footer';
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../Utilities/Carousel.css'
import RainingMarker from '../static/raining marker.png'
import HighlightedRainingMarker from '../static/highlighted raining marker.png'
import GreenRainingMarker from '../static/green raining marker.png'
import YellowRainingMarker from '../static/yellow raining marker.png'
import OrangeRainingMarker from '../static/orange raining marker.png'
import RedRainingMarker from '../static/red raining marker.png'
import * as L from "leaflet"
import axios from 'axios';

import { BaseUrlConfig } from '../BaseUrlConfig';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            card_page: 0,
            current_page: 1,
            interval: '',
            data_interval: '',
            //temporary add this one due to vercel doesnt support asyncio create task
            auto_monitor_interval: '',
            marker: [
                [6.039, 116.12],
                [2.91, 101.655],
                [4.61, 101.09],
                [3.42, 101.79],
                [1.47, 103.90],
                [6.40, 100.13],
                [5.93, 116.05],
                [2.28, 111.83],
                [3.79, 103.32],
                [6.17, 102.22]
            ],
            marker_visibility: false,
            alert_success: false,
            alert_deactivate: false,
            alert_process: false,
            alert_failure: false,
            alert_error: false,

            popup_active: false,

            loading: false,
            manual_active_request: false,

            isFullScreen: false,
            selectedImage: null
        }
        this.buttonRef = React.createRef()
    }

    componentDidMount() {
        // this.testcarouselfunction = setInterval(() => this.testautocarousel(), 5000)
        // this.renderRows()
        // const intervalId = setInterval(this.testautocarousel, 5000)
        // this.setState({ interval: intervalId })

        this.setState({
            loading: true
        })

        this.fetchData()

        setInterval(() => {
            this.setState({
                marker_visibility: !this.state.marker_visibility
            })
        }, 1000)

        const intervalId = setInterval(this.fetchData, 10000)
        this.setState({ data_interval: intervalId })
    }

    componentWillUnmount() {
        // clearInterval(this.testautocarousel)
        clearInterval(this.state.interval)
        clearInterval(this.state.data_interval)
    }

    fetchData = () => {
        if (this.state.manual_active_request === true) {
            console.log("Manual active, dont do anything")
        }
        else {
            axios.post(BaseUrlConfig() + '/specconnect/get', {
                "user_tag": localStorage.getItem("user_tag")
            })
                .then(response => {
                    console.log(response)
                    this.setState({
                        data: response.data,
                        loading: false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        alert_error: true
                    })
                    setTimeout(
                        () => this.setState({ alert_error: false }), 5000
                    )
                })
        }
    }

    fetchDataManual = () => {
        axios.post(BaseUrlConfig() + '/specconnect/get', {
            "user_tag": localStorage.getItem("user_tag")
        })
            .then(response => {
                this.setState({
                    data: response.data,
                    manual_active_request: false
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    testautocarousel = () => {
        // this.buttonRef.current.page_btn[this.state.current_page + 1].click();
        var page = parseInt(this.state.current_page) + 1
        var button = document.getElementById(`carousel-btn-${page}`)
        if (button === null) {
            this.setState({
                current_page: 1
            }, () => {
                button = document.getElementById(`carousel-btn-${this.state.current_page}`)
                button.click()
            })
        }
        else {
            page = this.state.current_page + 1
            this.setState({
                current_page: page
            })
            button.click()
        }
    }

    stopInterval = () => {
        console.log("Mouse entered!")
        clearInterval(this.state.interval)
    }

    startInterval = () => {
        console.log("Mouse left!")
        const intervalId = setInterval(this.testautocarousel, 5000)
        this.setState({ interval: intervalId })
    }

    // renderRows = () => {
    //     let finalArr = [], columns = [], page = 1, paginationArr = [], paginationFinalArr = [], superFinalArr = []

    //     this.state.data.forEach((row, idx) => {
    //         columns.push(
    //             <div className="card md:w-full bg-base-100 shadow-xl border border-gray-500">
    //                 <div key={idx} className="card-body">
    //                     <div className="overflow-x-auto -m-6">
    //                         <table className="table">
    //                             {/* head */}
    //                             <thead>
    //                                 <tr>
    //                                     <th colSpan={2}
    //                                         className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% 
    //                                     to-emerald-500 to-90% text-white text-lg">
    //                                         Station Name {idx + 1}
    //                                     </th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {/* row 1 */}
    //                                 <tr>
    //                                     <td>Rainfall Level: </td>
    //                                     <td>{row.value}</td>
    //                                 </tr>
    //                                 {/* row 2 */}
    //                                 <tr>
    //                                     <td>Highest Level: </td>
    //                                     <td>{row.description}</td>
    //                                 </tr>
    //                                 {/* row 2 */}
    //                                 <tr>
    //                                     <td>Latest Data Date: </td>
    //                                     <td>01/08/2023 22:00:00</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </div>
    //                 </div>
    //             </div>
    //         )

    //         if (columns.length === 3) {
    //             finalArr.push(
    //                 <div id={`item${page}`} className="carousel-item w-full justify-center items-center">
    //                     <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
    //                         {columns}
    //                     </div>
    //                 </div>
    //             )
    //             paginationArr.push(
    //                 <a href={`#item${page}`} className="btn btn-xs" id={`carousel-btn-${page}`}
    //                     onClick={(e) => this.testclick(e)}
    //                 >{page}</a>
    //             )
    //             columns = []
    //             page += 1
    //         }
    //         else {
    //             if (columns.length !== 0 && (idx + 1) === this.state.data.length) {
    //                 finalArr.push(
    //                     <div id={`item${page}`} className="carousel-item w-full justify-center items-center">
    //                         <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
    //                             {columns}
    //                         </div>
    //                     </div>
    //                 )
    //                 paginationArr.push(
    //                     <a href={`#item${page}`} className="btn btn-xs" id={`carousel-btn-${page}`}
    //                         onClick={(e) => this.testclick(e)}
    //                     >{page}</a>
    //                 )
    //                 columns = []
    //             }
    //         }
    //     });
    //     superFinalArr.push(
    //         <div>
    //             <div className="carousel w-full" onMouseEnter={this.stopInterval}
    //                 onMouseLeave={this.startInterval}>
    //                 {finalArr}
    //             </div>
    //             <div className="flex justify-center w-full py-2 gap-2">
    //                 {paginationArr}
    //             </div>
    //         </div>
    //     )
    //     return superFinalArr;
    // }

    renderRows = () => {
        let finalArr = [], columns = [], page = 1, paginationArr = [], superFinalArr = []

        this.state.data.forEach((row, idx) => {
            // columns.push(
            //     <div className="card md:w-full bg-base-100 shadow-xl border border-gray-500">
            //         <div key={idx} className="card-body">
            //             <div className="overflow-x-auto -m-6">
            //                 <table className="table">
            //                     {/* head */}
            //                     <thead>
            //                         <tr>
            //                             <th colSpan={2}
            //                                 className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% 
            //                             to-emerald-500 to-90% text-white text-lg">
            //                                 {row.name}
            //                             </th>
            //                         </tr>
            //                     </thead>
            //                     <tbody>
            //                         {/* row 1 */}
            //                         <tr>
            //                             <td>Rainfall Level: </td>
            //                             <td>{row.value}</td>
            //                         </tr>
            //                         {/* row 2 */}
            //                         <tr>
            //                             <td>Highest Level: </td>
            //                             <td>{row.description}</td>
            //                         </tr>
            //                         {/* row 2 */}
            //                         <tr>
            //                             <td>Latest Data Date: </td>
            //                             <td>01/08/2023 22:00:00</td>
            //                         </tr>
            //                     </tbody>
            //                 </table>
            //             </div>
            //         </div>
            //     </div>
            // )

            columns.push(
                <div className="card md:w-full bg-base-100 shadow-xl border border-gray-500">
                    <div key={row.serial_number} className="card-body">
                        <div className="overflow-x-auto -m-6">
                            <table className="table">
                                {/* head */}
                                <thead>
                                    <tr onClick={() => this.goDataPage(row.serial_number)}>
                                        <th colSpan={2}
                                            className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% 
                                        to-emerald-500 to-90% text-white text-lg cursor-pointer">
                                            {row.station_name}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* row 1 */}
                                    <tr>
                                        <td>Bacaan Hujan: </td>
                                        <td>{row.value}</td>
                                    </tr>
                                    {/* row 2 */}
                                    <tr>
                                        <td>Penerangan: </td>
                                        <td>{row.description}</td>
                                    </tr>
                                    {/* row 2 */}
                                    <tr>
                                        <td>Tarikh data terkini: </td>
                                        <td>{row.query_datetime}</td>
                                    </tr>
                                    <tr>
                                        <td>Bateri Logger (V): </td>
                                        <td>{row.logger_battery === "N/A" ?
                                            row.logger_battery
                                            :
                                            row.logger_battery.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Bateri WatchDog (%): </td>
                                        <td>{row.watchdog_battery}</td>
                                    </tr>
                                    {row.station_cctv_image !== "" ?
                                        <tr>
                                            <td>Gambar Stesen</td>
                                            <td>{row.image_created_at}</td>
                                        </tr>
                                        :
                                        null}

                                    {row.station_cctv_image !== "" ?
                                        <tr onClick={() => this.openFullScreen(BaseUrlConfig() + row.station_cctv_image)}>
                                            <td colSpan={2}>
                                                <img style={{ objectFit: true }} alt="#"
                                                    src={BaseUrlConfig() + row.station_cctv_image}
                                                     />
                                            </td>
                                        </tr>
                                        :
                                        null}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )

            if (columns.length === 3) {
                finalArr.push(
                    <div id={`slide${page}`} className="w-full justify-center items-center">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                            {columns}
                        </div>
                    </div>
                )
                paginationArr.push(
                    <a href={`#item${page}`} className="btn btn-xs" id={`carousel-btn-${page}`}
                        onClick={(e) => this.testclick(e)}
                    >{page}</a>
                )
                columns = []
                page += 1
            }
            else {
                if (columns.length !== 0 && (idx + 1) === this.state.data.length) {
                    finalArr.push(
                        <div id={`slide${page}`} className="w-full justify-center items-center">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                {columns}
                            </div>
                        </div>
                    )
                    paginationArr.push(
                        <a href={`#item${page}`} className="btn btn-xs" id={`carousel-btn-${page}`}
                            onClick={(e) => this.testclick(e)}
                        >{page}</a>
                    )
                    columns = []
                }
            }
        });
        superFinalArr.push(
            <Carousel autoPlay infiniteLoop swipeable
                showArrows={false} interval={5000} showStatus={false}
                showThumbs={false} swipeScrollTolerance={50}
                preventMovementUntilSwipeScrollTolerance>
                {finalArr}
            </Carousel>
        )
        return superFinalArr;
    }

    testclick = (event) => {
        this.setState({
            current_page: event.target.innerText
        })
    }

    manualSwitch = (id, serial_number) => {
        let station_data = [...this.state.data]
        station_data[id].checked = !station_data[id].checked

        this.setState({ alert_process: true })
        axios.post(BaseUrlConfig() + "/specconnect/triggerManualSiren", {
            "serial_number": serial_number,
            "checked": station_data[id].checked,
            "manual_active_request": true
        })
            .then(response => {
                if (response.data === "OK") {
                    this.setState({
                        alert_success: true,
                        alert_process: false
                    })
                    this.fetchDataManual()
                    setTimeout(
                        () => this.setState({ alert_success: false }), 2000
                    )
                }
                //temporary test http
                else {
                    this.setState({
                        alert_success: true,
                        alert_process: false
                    })
                    this.fetchDataManual()
                    setTimeout(
                        () => this.setState({ alert_success: false }), 2000
                    )
                    console.log(response.data)
                }
            })
            .catch(error => {
                this.setState({
                    alert_process: false,
                    alert_failure: true
                })
                setTimeout(
                    () => this.setState({ alert_failure: false }), 2000
                )
                console.log(error)
            })
    }

    popupHandler = () => {
        this.setState({
            popup_active: !this.state.popup_active
        })
    }

    renderMarker = () => {
        return this.state.data.map((row, idx) => {
            return (
                <React.Fragment>
                    <Marker key={row.serial_number} position={row["location"]}
                        icon={this.getIcon(row)}
                        opacity={
                            row.checked || row.value >= 10 ?
                                this.state.marker_visibility ?
                                    0
                                    :
                                    1
                                :
                                1
                        }

                    >
                        <Popup autoPan={false}>
                            <div className="grid grid-cols-1 md:grid-cols-2 w-48 h-108">
                                <div className="col-span-2 text-center font-black text-2xl">
                                    <h1>{row.station_name}</h1>
                                </div>
                                <div className="flex items-center justify-center col-span-2">
                                    <img src={BaseUrlConfig() + "/static/resources/" + row.station_image} className="rounded-lg" alt="#"
                                        style={{ fill: 'red' }} />
                                </div>
                                <div>
                                    <p>Bacaan Stesen: </p>
                                </div>
                                <div>
                                    <p>{row.value}</p>
                                </div>
                                <div>
                                    <p>Penerangan: </p>
                                </div>
                                <div>
                                    <p>{row.description}</p>
                                </div>
                                <div>
                                    <p>Lat; Lng: </p>
                                </div>
                                <div>
                                    <p>{"[" + row["location"][0] + ", " + row["location"][1] + "]"}</p>
                                </div>
                                <div className="flex items-center justify-center col-span-2 font-extrabold font-lg">
                                    Suis Manual
                                </div>
                                <div className="flex items-center justify-center col-span-2">
                                    <input type="checkbox" className="toggle toggle-error" checked={row.checked} onChange={() => this.manualSwitch(idx, row.serial_number)} />
                                </div>
                            </div>
                        </Popup>
                    </Marker>
                </React.Fragment>
            )
        })
    }

    getIcon = (row) => {
        if (row.checked === false) {
            if (row.value >= 10 && row.value <= 10) {
                return new L.Icon({
                    iconUrl: GreenRainingMarker,
                    iconSize: [35, 35],
                    iconAnchor: [18, 35],
                    popupAnchor: [1, -25]
                });
            }
            else if (row.value >= 11 && row.value <= 30) {
                return new L.Icon({
                    iconUrl: YellowRainingMarker,
                    iconSize: [35, 35],
                    iconAnchor: [18, 35],
                    popupAnchor: [1, -25]
                });
            }
            else if (row.value >= 31 && row.value <= 60) {
                return new L.Icon({
                    iconUrl: OrangeRainingMarker,
                    iconSize: [35, 35],
                    iconAnchor: [18, 35],
                    popupAnchor: [1, -25]
                });
            }
            else if (row.value >= 60) {
                return new L.Icon({
                    iconUrl: RedRainingMarker,
                    iconSize: [35, 35],
                    iconAnchor: [18, 35],
                    popupAnchor: [1, -25]
                });
            }
            else {
                return new L.Icon({
                    iconUrl: RainingMarker,
                    iconSize: [35, 35],
                    iconAnchor: [18, 35],
                    popupAnchor: [1, -25]
                });
            }
        }
        else {
            return new L.Icon({
                iconUrl: HighlightedRainingMarker,
                iconSize: [35, 35],
                iconAnchor: [18, 35],
                popupAnchor: [1, -25]
            });
        }
    }

    goDataPage = (serial_number) => {
        localStorage.setItem("serial_number", serial_number)
        window.location.href = "/Data"
    }

    openFullScreen = (imageSrc) => {
        console.log("Clicked")
        this.setState({
            selectedImage: imageSrc,
            isFullScreen: true
        })
    }

    closeFullScreen = () => {
        this.setState({
            selectedImage: null,
            isFullScreen: false
        })
    }

    render() {
        const loadingContent = (
            <React.Fragment>
                <div className="flex justify-center pt-10">
                    <span className="loading loading-ring loading-lg text-success w-2/5"></span>

                </div>
                <br />

                <div className="flex justify-center">
                    <a href="#!" className="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                            animate-pulse">
                            Sila tunggu, data sedang dimuatkan...</span>
                    </a>
                </div>


            </React.Fragment>
        )

        const mainContent = (
            <React.Fragment>
                <div className="w-screen md:w-full h-[calc(100vh-20rem)]">
                    <MapContainer center={[3.07436, 108.11703]}
                        zoom={6}
                        scrollWheelZoom={true}
                        style={{ height: "100%", width: "100%" }}
                    >

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {this.renderMarker()}

                    </MapContainer>

                </div >

                <div className="collapse collapse-arrow bg-base-200">
                    <input type="checkbox" className="peer" />
                    <div className="collapse-title bg-base-200">
                        Klik di sini untuk melihat Label & Penanda
                    </div>
                    <div className="collapse-content bg-base-400 peer-checked:bg-gray-200">
                        <div className="font-bold pt-6">
                            <div className="text-left text-2xl underline underline-offset-8 p-4">
                                Label & Penanda
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2">
                                <div className="flex items-center justify-center">
                                    <img src={RainingMarker} className="object-contain h-12 w-12" alt="#" />
                                    <span>Siren Tidak Aktif</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={HighlightedRainingMarker} className="object-contain h-12 w-12" alt="#" />
                                    <span>Siren Aktif</span>
                                </div>
                            </div>

                            <br />
                            <br />

                            <div className="text-left text-l underline underline-offset-8 p-4">
                                Kategori Keamatan Hujan (dalam satu jam)
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-4">
                                <div className="flex items-center justify-center">
                                    <img src={GreenRainingMarker} className="object-contain h-12 w-12" alt="#" />
                                    <span>Renyai 1-10 (mm)</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={YellowRainingMarker} className="object-contain h-12 w-12" alt="#" />
                                    <span>Sederhana 11-30 (mm)</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={OrangeRainingMarker} className="object-contain h-12 w-12" alt="#" />
                                    <span>Lebat 31-60 (mm)</span>
                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={RedRainingMarker} className="object-contain h-12 w-12" alt="#" />
                                    <span>Sangat Lebat &gt;60 (mm)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="px-5">
                    {this.renderRows()}
                </div>

                {
                    this.state.alert_success ?
                        <div className="alert alert-success fixed bottom-0 z-20">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>Triggered successfully messsage here!</span>
                        </div>
                        :
                        null
                }

                {
                    this.state.alert_deactivate ?
                        <div className="alert alert-error fixed bottom-0 z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>Deactivating alert message here!</span>
                        </div>
                        :
                        null
                }

                {
                    this.state.alert_process ?
                        <div className="alert alert-info fixed bottom-0 z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <span>Sending signal...</span>
                        </div>
                        :
                        null
                }

                {
                    this.state.alert_failure ?
                        <div className="alert alert-error fixed bottom-0 z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>Failed to connect to the siren device.</span>
                        </div>
                        :
                        null
                }

                {
                    this.state.alert_error ?
                        <div className="alert alert-error fixed bottom-0 z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>Failed to fetch data. Please refresh the page to try again.</span>
                        </div>
                        :
                        null
                }
            </React.Fragment >
        )
        return (
            <React.Fragment>
                {this.state.loading ?
                    <NavSideBar pageContent={loadingContent} />
                    :
                    <NavSideBar pageContent={mainContent} />
                }

                {/* Full-Screen Modal */}
                {this.state.isFullScreen && this.state.selectedImage && (
                    <div
                        className="full-screen-modal"
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                        }}
                        onClick={this.closeFullScreen}
                    >
                        <img
                            src={this.state.selectedImage}
                            alt="full-screen"
                            style={{
                                maxWidth: '90%',
                                maxHeight: '90%',
                                cursor: 'zoom-out',
                            }}
                        />
                    </div>
                )}

                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(Home);
